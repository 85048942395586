body, p {
  margin: 0;
  overflow-x: hidden;
}

@font-face {
  font-family: DancingScript-VariableFont;
  src: url(font/DancingScript-VariableFont_wght.ttf) format("truetype");
}

.home {
  width: 100%;
  height: 100vh;
}

.start {
  background-image: url('./img/duke&mosaturation.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.pic-text {
  color: white;
  font-size: 40px;
  /* width: 100%; */
  /* left:50%; */
}

.home-section {
  width: 100%;
  min-height: 80vh;
  height: fit-content;
}

.home p {
  position: relative;
}

nav {  
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px black;
  background-color: white;
  padding: 10px;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 4rem
}

.menu-icon {
  cursor: pointer;
  font-size: 40px;
  right: 2rem;
  z-index: 10;
  position: absolute;
}

.title {
  font-size: 60px;
  font-family: DancingScript-VariableFont;
}

.clock p {
  color: white;
  margin: 0;
}

.menu {
  background-color: white;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
  align-items: center;
  top: 5.25rem;
  right: -30rem;
  position: absolute;
  transition: right 1s ease-in-out;
}

.menu-on-screen {
  right: 0;
}

.menu li {
  border-bottom: 1px solid black;
  list-style: none;
  overflow: hidden;
  width: 20rem;
  height: 5rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu li:hover {
  cursor: pointer;
  background-color: #7eccf0;
}

.our-story-home,
.contributors-home {
  background-color: #7eccf0;
}

.our-story-home,
.contributors-home,
.registry-home {
  display: flex;
}

.our-story {
  display: flex;
  justify-content: space-between;
}

.duke-story {
  width: 45%;
  margin-left: 1rem;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 35px;
}

.mo-story {
  width: 45%;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 35px;
}

.together-story {
  left: 20%;
  width: fit-content;
  font-family: Poppins-Regular, sans-serif !important;
  position:relative;
  font-size: 35px;
}

@font-face {
  font-family: Poppins-Regular;
  src: url(font/Poppins-Regular.ttf) format('truetype');
}

.font-view {
  font-family: Poppins-Regular, sans-serif !important;
  /* font-size: .1rem !important; */
  font-size: 35px;
}

a img {
  width: 20rem;
  padding: 1rem;
}

/* h1 {
  font-family: Poppins-Regular, sans-serif !important;
} */

@media only screen and (max-width: 500px) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }
  .title {
    font-size: 40px !important;
  }
  nav {
    width: 100vw;
    /* position: sticky; */
  }
  .pic-text {
    font-size: 15px;
  }
  .start {
    height: 25vh;
  }
  .duke-story,
  .mo-story,
  .together-story,
  .font-view{
    font-size: 1rem;
  }
  .home-section{
    min-height: 1vh;
  }
  .home {
    padding-top: 5rem;
    /* min-height: 80vh; */
  }
  .menu {
    top: -50rem;
    right: 0;
  }
  .menu-on-screen {
    top: 5.25rem;
    right: 0;
  }

}

/* .our-story {
  display: flex;
  justify-content: space-between;
}

.duke-story {
  width: 45%;
  margin-left: 1rem;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 35px;
}

.mo-story {
  width: 45%;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 35px;
}

.together-story {
  left: 50%;
  font-family: Poppins-Regular, sans-serif !important;
  position:relative;
  font-size: 35px;
} */